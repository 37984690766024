import React, { FC, useCallback, useReducer } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { PositionSide } from '@dextoroprotocol/sdk/types'
import { showMobileTradePanel, showNotification } from 'state/app/reducer'
import { selectMobileTradePanel, selectNotification } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setTradePanelDrawerOpen } from 'state/futures/reducer'
import { setLeverageSide } from 'state/futures/smartMargin/reducer'

import { NotifyIconMobile, NotifyIconMobileAlert } from 'components/Icons/NotifyIcon'
import Button from 'components/Button'
import CloseIcon from 'assets/svg/app/close.svg'
import MenuIcon from 'assets/svg/app/menu.svg'
import MobileTradePanel from 'sections/futures/Trade/MobileTradePanel'
import MobileMenuModal from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu/MobileMenuModal'
import MobileWalletButton from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu/MobileWalletButton'
import ROUTES from 'constants/routes'
import { zIndex } from 'constants/ui'
import { MOBILE_FOOTER_HEIGHT } from 'constants/ui'
import { DEFAULT_FUTURES_MARGIN_TYPE } from 'constants/defaults'
import MobileFooter from './MobileFooter'
import { MobileFooterIconContainer, MobilePageTitle } from 'styles/common'
import Notifications from '../Notifications'
import { get } from 'utils/localStore'

const MobileUserMenu: FC = () => {
	const [isOpen, toggleOpen] = useReducer((s) => !s, false)
	const { t } = useTranslation()
	const { asPath } = useRouter()

	const dispatch = useAppDispatch()
	const handleNotification = () => dispatch(showNotification(!enableNotification))
	const selectShowMobileTradePanel = useAppSelector(selectMobileTradePanel)
	const enableNotification = useAppSelector(selectNotification)
	const unreadNotifications = get('unreadNotifications')
	const NotificationIcon = React.useMemo(() => {
		return unreadNotifications ? <NotifyIconMobileAlert /> : <NotifyIconMobile />
	}, [unreadNotifications])

	const handleSideSelect = useCallback(
		(side: PositionSide) => () => {
			dispatch(setLeverageSide(side))
			dispatch(setTradePanelDrawerOpen(true))
		},
		[dispatch]
	)

	const TradePanelFooter: React.FC = () => (
		<>
			<MobileFooterIconContainer
				onClick={() => dispatch(showMobileTradePanel(!selectShowMobileTradePanel))}
			>
				<CloseIcon height={25} width={25} />
			</MobileFooterIconContainer>
			<Divider />
			<FooterRight>
				<MobilePageTitle>Select Market</MobilePageTitle>
			</FooterRight>
		</>
	)

	const NotificationFooter: React.FC = () => (
		<>
			<MobileFooterIconContainer onClick={handleNotification}>
				<CloseIcon height={25} width={25} />
			</MobileFooterIconContainer>
			<Divider />
			<FooterRight>
				<MobilePageTitle>Notifications</MobilePageTitle>
			</FooterRight>
		</>
	)

	return (
		<>
			<MobileFooterContainer>
				{selectShowMobileTradePanel ? (
					<TradePanelFooter />
				) : enableNotification ? (
					<NotificationFooter />
				) : (
					<>
						<MobileFooterIconContainer onClick={toggleOpen}>
							{isOpen ? <CloseIcon height={25} width={25} /> : <MenuIcon height={25} width={25} />}
						</MobileFooterIconContainer>
						<Divider />
						{!isOpen && (
							<>
								<MobileFooterIconContainer onClick={handleNotification}>
									{NotificationIcon}
								</MobileFooterIconContainer>
								<Divider />
							</>
						)}
						<FooterRight>
							{window.location.pathname === ROUTES.Home.Root ? (
								<Link href={ROUTES.Markets.Home(DEFAULT_FUTURES_MARGIN_TYPE)}>
									<Button size="small">{t('homepage.nav.start-trade')}</Button>
								</Link>
							) : asPath.split('/').includes('market') && !isOpen ? (
								<PositionButtonsContainer>
									<PositionButton
										data-testid="position-side-long-button"
										$position={PositionSide.LONG}
										$isActive={true}
										onClick={handleSideSelect(PositionSide.LONG)}
									>
										Buy
									</PositionButton>
									<PositionButton
										data-testid="position-side-short-button"
										$position={PositionSide.SHORT}
										$isActive={true}
										$right={true}
										onClick={handleSideSelect(PositionSide.SHORT)}
									>
										Sell
									</PositionButton>
								</PositionButtonsContainer>
							) : isOpen ? (
								<MobileFooter toggleModal={toggleOpen} />
							) : (
								<MobileWalletButton toggleModal={toggleOpen} />
							)}
						</FooterRight>
					</>
				)}
			</MobileFooterContainer>
			{isOpen && <MobileMenuModal onDismiss={toggleOpen} />}
			{selectShowMobileTradePanel && <MobileTradePanel />}
			{enableNotification && <Notifications />}
		</>
	)
}

type PositionButtonProps = {
	$position: PositionSide
	$isActive: boolean
	$right?: boolean
}

const MobileFooterContainer = styled.footer`
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: ${MOBILE_FOOTER_HEIGHT};
	z-index: ${zIndex.MOBILE_FOOTER};
	margin-top: -1px;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	border-top: ${(props) => props.theme.colors.selectedTheme.border};
`

const FooterRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: auto 20px;
	width: 100%;
`

const PositionButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex: 1 1 auto;
	align-items: center;
`

const PositionButton = styled.div<PositionButtonProps>`
	display: flex;
	flex: 0 0 calc(50% - 6px);
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	padding: 1px 16px 0 16px;
	// padding: 0 16px;
	min-height: 44px;
	height: 44px;
	font-size: 17px;
	// line-height: 20px;
	border-radius: 10px;
	text-transform: uppercase;
	transition: color 0.2s ease-in-out;
	line-height: normal;

	${(props) => css`
		font-family: ${props.theme.fonts.bold};
		color: ${props.theme.colors.selectedTheme.newTheme.tabs.position.color};
	`}

	${(props) =>
		props.$isActive &&
		css`
			color: ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position].color};
			border: 2px ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position].color}
				solid;
			background: ${props.theme.colors.selectedTheme.newTheme.tabs.position[props.$position]
				.background};
		`}
`

const Divider = styled.span`
	width: 1px;
	margin: auto;
	height: calc(100% - 24px);
	background: ${(props) => props.theme.colors.selectedTheme.borderColor};
`

export default MobileUserMenu
