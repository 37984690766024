import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, FunctionComponent, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAccount } from 'wagmi'

import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'
import MainnetIcon from 'assets/svg/app/maninnet.svg'
import DextoroYellowIcon from 'assets/svg/brand/logo-yellow.svg'
import CommunityIcon from 'assets/svg/social/community.svg'
import Docs2Icon from 'assets/svg/social/docs.svg'
import MetricsIcon from 'assets/svg/social/matric.svg'
import BlogIcon from 'assets/svg/social/blog.svg'
import WalletIcon from 'assets/svg/social/wallet.svg'
import XIcon from 'assets/svg/social/x.svg'
import InstaIcon from 'assets/svg/social/instagram.svg'
import YoutubeIcon from 'assets/svg/social/youtube.svg'
import MoreIcon from 'assets/svg/app/link-new.svg'
import TermsIcon from 'assets/svg/app/terms-icon.svg'

import Badge from 'components/Badge'
import { FlexDivRow } from 'components/layout/flex'
import LabelContainer from 'components/Nav/DropDownLabel'
import Select from 'components/Select'
import { DropdownIndicator, IndicatorSeparator } from 'components/Select'
import SmartMarginOnboardModal from 'sections/futures/Trade/SmartMarginOnboardModal'

import { EXTERNAL_LINKS } from 'constants/links'
import { setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { linkCSS } from 'styles/common'
import { DESKTOP_NAV_LINKS, Badge as BadgeType } from './constants'
import { selectSmartMarginActivePositions } from 'state/futures/smartMargin/selectors'
import ROUTES from 'constants/routes'
import { FuturesMarginType } from '@dextoroprotocol/sdk/types'

type ReactSelectOptionProps = {
	i18nLabel: string
	postfixIcon?: string
	isActive: boolean
	link: string
	isParentLink?: boolean
	badge: BadgeType[]
	Icon: FunctionComponent<any>
}

const links = [
	{ icon: <XIcon />, title: 'Twitter', href: EXTERNAL_LINKS.Social.Twitter },
	{ icon: <CommunityIcon />, title: 'Discord', href: EXTERNAL_LINKS.Social.Discord },
	{ icon: <BlogIcon />, title: 'Blog', href: EXTERNAL_LINKS.Social.Blog },
	{ icon: <InstaIcon />, title: 'Instagram', href: EXTERNAL_LINKS.Social.Instagram },
	{ icon: <YoutubeIcon />, title: 'Youtube', href: EXTERNAL_LINKS.Social.Youtube },
	{ icon: <Docs2Icon />, title: 'Documentation', href: EXTERNAL_LINKS.Docs.Documentation },
	{ icon: <WalletIcon />, title: 'Smart Wallet', href: EXTERNAL_LINKS.Docs.SmartWallet },
	{ icon: <MetricsIcon />, title: 'Metrics', href: EXTERNAL_LINKS.Docs.Metrics },
	{ icon: <TermsIcon />, title: 'Terms of Use', href: EXTERNAL_LINKS.Docs.Terms, isTerms: true },
]

const Nav: FC = memo(() => {
	const { t } = useTranslation()
	const { asPath } = useRouter()
	const { isConnected } = useAccount()
	const [openSubMenu, setOpenSubMenu] = useState(false)
	const [mainnetMenu, setMainnetMenu] = useState(false)
	const [translateMenu, setTranslateMenu] = useState(false)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const asset = smartMarginPositions.length > 0 ? smartMarginPositions[0].market.asset : 'sETH'
	const marketUrl = ROUTES.Markets.Home(FuturesMarginType.SMART_MARGIN, asset)

	const openModal = useAppSelector(selectShowModal)
	const dispatch = useAppDispatch()
	const formatOptionLabel = ({
		i18nLabel,
		Icon,
		badge,
		link,
		isActive,
		isParentLink,
	}: ReactSelectOptionProps) => {
		if (i18nLabel === 'header.nav.leaderboard' || i18nLabel === 'header.nav.options.title') {
			return (
				<MenuInside isDropDown isActive={isActive}>
					{t(i18nLabel)}
				</MenuInside>
			)
		}

		const option = (
			<LabelContainer>
				<NavLabel isActive={isActive || !isParentLink}>
					{t(i18nLabel)}
					{badge?.map(({ i18nLabel, color }) => (
						<Badge color={color}>{t(i18nLabel)}</Badge>
					))}
				</NavLabel>
				{Icon && <Icon />}
			</LabelContainer>
		)
		if (link) {
			return <Link href={link}>{option}</Link>
		}

		return option
	}

	return (
		<nav>
			<MenuLinks>
				<div
					onClick={() => {
						setMainnetMenu(!mainnetMenu)
						setTranslateMenu(false)
						setOpenSubMenu(false)
					}}
				>
					<BorderRightDiv>
						<MenuInsideDropDown isActive={mainnetMenu}>
							<DropDownContainer>
								<StyledMainnetIcon width={18}></StyledMainnetIcon>
								Mainnet <StyledDropdownArrow isActive={mainnetMenu} />
							</DropDownContainer>
						</MenuInsideDropDown>
					</BorderRightDiv>
					{mainnetMenu && (
						<>
							<OpenMenuBackground></OpenMenuBackground>
							<OpenSubMenu>
								<SubMenuLink onClick={() => setMainnetMenu(false)}>
									<MainnetIcon width={15} height={15}></MainnetIcon>
									<ParaStyle color="secondary">Mainnet</ParaStyle>
								</SubMenuLink>
							</OpenSubMenu>
						</>
					)}
				</div>
				<div
					onClick={() => {
						setTranslateMenu(!translateMenu)
						setMainnetMenu(false)
						setOpenSubMenu(false)
					}}
				>
					<BorderRightDiv>
						<MenuInsideDropDown isActive={translateMenu}>
							<DropDownContainer>
								English <StyledDropdownArrow isActive={translateMenu} />
							</DropDownContainer>
						</MenuInsideDropDown>
					</BorderRightDiv>
					{translateMenu && (
						<>
							<OpenMenuBackground></OpenMenuBackground>
							<OpenSubMenu>
								<SubMenuLink onClick={() => setTranslateMenu(false)}>
									<ParaStyle color="secondary">English</ParaStyle>
								</SubMenuLink>
							</OpenSubMenu>
						</>
					)}
				</div>
				<HeaderMenuLinks>
					{DESKTOP_NAV_LINKS.map(({ i18nLabel, link: url, links }) => {
						const basePath1 = asPath.split('/')[1]
						const basePath2 = asPath.split('/')[2]
						const pathName =
							basePath1 === 'portfolio'
								? '/' + basePath1 + (basePath2 ? '/' + basePath2 : '')
								: basePath1

						const activeUrl =
							url.indexOf('/market') === 0
								? url.split('/')[1].split('?')[0]
								: url.indexOf('/portfolio') === 0
								? url
								: url.split('/')[1]
						const isActive =
							pathName === activeUrl ||
							((pathName === '/portfolio/history' ||
								pathName === '/portfolio/positions' ||
								pathName === '/portfolio/orders' ||
								pathName === '/portfolio/fees') &&
								activeUrl === '/portfolio')

						if (!links) {
							if (i18nLabel === 'dashboard.titles.profile' && !isConnected) return null
							return i18nLabel === 'header.nav.exchange' ? (
								<Link
									key="#"
									href="#"
									onClick={() => dispatch(setOpenModal('futures_smart_margin_socket'))}
								>
									<MenuInside isActive={isActive}>{t(i18nLabel)}</MenuInside>
								</Link>
							) : (
								<Link
									key={i18nLabel === 'header.nav.markets' ? marketUrl : url}
									href={i18nLabel === 'header.nav.markets' ? marketUrl : url}
								>
									{i18nLabel === 'header.nav.markets' ? (
										<MenuInside isActive={isActive}>
											<FlexDivRow>
												{t(i18nLabel)}
												<DextoroYellowIcon height={18} width={18} style={{ paddingLeft: 2 }} />
											</FlexDivRow>
										</MenuInside>
									) : (
										<MenuInside isActive={isActive}>{t(i18nLabel)}</MenuInside>
									)}
								</Link>
							)
						}

						return (
							<DropDownSelect
								key={url}
								variant="transparent"
								formatOptionLabel={formatOptionLabel}
								controlHeight={34}
								options={links}
								value={{ i18nLabel, isActive, isParentLink: true }}
								components={{ IndicatorSeparator, DropdownIndicator }}
								isSearchable={false}
							/>
						)
					})}
				</HeaderMenuLinks>
				<div
					onClick={() => {
						setMainnetMenu(false)
						setTranslateMenu(false)
						setOpenSubMenu(!openSubMenu)
					}}
					style={{ position: 'relative', marginLeft: '3px' }}
				>
					<MenuInsideDropDown isActive={openSubMenu}>
						<DropDownContainer>
							More <StyledDropdownArrow isActive={openSubMenu}></StyledDropdownArrow>
						</DropDownContainer>
					</MenuInsideDropDown>
					{openSubMenu && (
						<>
							<OpenMenuBackground></OpenMenuBackground>
							<OpenMenu>
								{links.map((link, i) =>
									link.isTerms ? (
										<StyledLink key={i} href={link.href}>
											<LinkDiv className={asPath.includes('/terms') ? 'active-link' : ''}>
												{link.icon}
												<ParaStyle>{link.title}</ParaStyle>
											</LinkDiv>
										</StyledLink>
									) : (
										<NavLink key={i} href={link.href}>
											<LinkDiv>
												{link.icon}
												<ParaStyle>
													{link.title} <MoreIcon className="link-icon" />
												</ParaStyle>
											</LinkDiv>
										</NavLink>
									)
								)}
							</OpenMenu>
						</>
					)}
				</div>
				{openModal === 'futures_smart_margin_socket' && (
					<SmartMarginOnboardModal
						onDismiss={() => {
							dispatch(setOpenModal(null))
						}}
					/>
				)}
			</MenuLinks>
		</nav>
	)
})

const MenuLinks = styled.ul`
	display: flex;
	padding: 0 3px 0 4px;
	gap: 4px;
	align-items: center;
`

const HeaderMenuLinks = styled.div`
	display: flex;
	width: auto;
	gap: 4px;
	max-width: calc(100% - 297px);
	@media (max-width: 1400px) {
		overflow-x: auto;
	}
`

const NavLabel = styled.div<{ isActive?: boolean }>`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 15px;
	line-height: 15px;
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.button.text.primary
			: props.theme.colors.selectedTheme.gray};
`
const DropDownContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
`

const MenuInside = styled.div<{ isActive: boolean; isDropDown?: boolean }>`
	${linkCSS};
	padding: 6px ${(props) => (props.isDropDown ? '0px' : '12px')};
	margin: 0px 3px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 15px;

	text-transform: capitalize;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
	width: 100%;
	background: ${(props) =>
		props.isActive ? props.theme.colors.selectedTheme.button.fill : 'transparent'};
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.button.text.primary
			: props.theme.colors.selectedTheme.gray};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
`
const MenuInsideDropDown = styled.div<{ isActive: boolean; isDropDown?: boolean }>`
	${linkCSS};
	padding: 6px ${(props) => (props.isDropDown ? '0px' : '12px')};

	margin-right: 4px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 15px;
	text-transform: capitalize;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
	min-width: 69px;
	width: 100%;
	background: ${(props) =>
		props.isActive ? props.theme.colors.selectedTheme.button.fill : 'transparent'};
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.button.text.primary
			: props.theme.colors.selectedTheme.gray};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
`
const DropDownSelect = styled(Select)`
	.react-select__menu {
		width: 180px;
		text-transform: capitalize;
		left: 0;
	}

	.react-select__option {
		svg {
			width: 14px;
			height: 14px;

			> path {
				fill: #ffb800;
			}
		}
	}

	.react-select__control {
		padding: 0 6px;
	}

	.react-select__group {
		padding: 20px;

		.react-select__group-heading {
			font-size: 12px;
			padding: 0;
			margin-bottom: 15px;
			text-transform: none;
		}
	}
	n .react-select__dropdown-indicator {
		margin-right: 5px;
		margin-top: 2px;
		padding: 0;
	}

	.react-select__value-container {
		padding: 0px;
		text-transform: capitalize;
		width: ${(props) => {
			//@ts-ignore
			return props.value?.i18nLabel === 'header.nav.markets'
				? '94px'
				: //@ts-ignore
				props.value?.i18nLabel === 'header.nav.leaderboard'
				? '110px'
				: //@ts-ignore
				props.value?.i18nLabel === 'header.nav.options.title'
				? '80px'
				: '100%'
		}};
	}

	.react-select__single-value {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.react-select__menu-notice--no-options {
		padding: 15px;
	}
`
const StyledDropdownArrow = styled(DropdownArrow)<{ isActive: boolean }>`
	path {
		fill: ${(props) =>
			props.isActive
				? props.theme.colors.selectedTheme.button.text.primary
				: props.theme.colors.selectedTheme.gray};
	}
	transform: ${(props) => (props.isActive ? 'rotate(180deg)' : 'rotate(360deg)')};
	transition: all 0.15s ease-in-out 0s !important;
	margin-left: 6px;
	margin-top: 1px;
	overflow: visible;
`
const StyledMainnetIcon = styled(MainnetIcon)<{ isActive: boolean }>`
	padding-bottom: 1px;
	path {
		fill: ${(props) =>
			props.isActive
				? props.theme.colors.selectedTheme.button.text.primary
				: props.theme.colors.selectedTheme.gray};
	}
`

const BorderRightDiv = styled.div`
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	height: 30px;
	display: flex;
	align-items: center;
`

const OpenMenu = styled.div`
	z-index: 1001;
	position: absolute;
	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
	font-feature-settings: 'zero' 0;
	left: calc(50% - 110px);
	top: 54px;
	border-radius: 8px;
	padding: 12px 20px;
	width: 220px;
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
`

const OpenMenuBackground = styled.div`
	z-index: 999;
	position: fixed;
	top: 44px;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(8px);
	left: 0;
`
const OpenSubMenu = styled.div`
	z-index: 1001;
	position: absolute;
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
	top: 57px;
	width: 110px;
	padding: 5px 10px;
	display: grid;
	border-radius: 11px;
	font-size: 15px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`

const NavLink = styled.a.attrs({ target: '_blank', rel: '_noreferrer' })`
	display: flex;
	align-items: center;
	text-decoration: none;
	user-select: none;
`
const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	user-select: none;
	&:hover {
		div {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
		}
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}
			rect,
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}
		}
	}
	.active-link {
		div {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
		}
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
			}
			rect,
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
			}
		}
	}
`
const LinkDiv = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	justify-content: start;
	align-items: center;
	padding: 8px 0px;
	margin: 0px;
	width: 100%;
	cursor: pointer;
	user-select: none;
	transition: all 0.15s ease-in-out 0s !important;
	color: ${(props) => props.theme.colors.selectedTheme.gray};

	svg {
		width: 18px;
		height: 16px;
		margin-right: 12px;
		transition: all 0.15s ease-in-out 0s !important;
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}
		}
	}

	.link-icon {
		margin-left: 6px;
		path {
			fill: none;
		}
	}

	&:hover {
		.link-icon {
			path {
				fill: none;
				stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
			}
		}
	}
`

const ParaStyle = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	line-height: 0;
`

const SubMenuLink = styled.a.attrs({ target: '_blank', rel: '_noreferrer' })`
	&:not(:last-of-type) {
		margin-right: 0px;
	}

	&:not(:first-child) {
		color: ${(props) => props.theme.colors.common.dark.gray};
		path {
			fill: ${(props) => props.theme.colors.common.dark.gray};
		}
	}

	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 10px;
	padding: 5px;
	cursor: pointer;
	min-height: 30px;
	path {
		fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}

	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};

		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
`

export default Nav
