import { FC, useRef } from 'react'
import styled, { css } from 'styled-components'

import { FlexDivCol } from 'components/layout/flex'
import { MobileHiddenView } from 'components/Media'

import Banner from '../../HomeLayout/Banner'
import Logo from '../../Logo'
import Connector from 'containers/Connector'

import Nav from './Nav'
import WalletButtons from './WalletButtons'
import CloseIconWithHover from 'sections/shared/components/CloseIconWithHover'
import Link from 'next/link'
import media from 'styles/media'
type LogoNavProps = {
	hasWalletAddress?: boolean
}
const Header: FC = () => {
	const { walletAddress } = Connector.useContainer()
	const showRestrictedBanner = sessionStorage.getItem('complianceBannerShown')
	const showRestrictedWarnig = useRef(true)
	const handleClose = () => {
		sessionStorage.removeItem('complianceBannerShown')
		showRestrictedWarnig.current = false
	}

	return (
		<MobileHiddenView>
			<FlexDivCol>
				<Container>
					<LogoNav hasWalletAddress={!!walletAddress}>
						<BorderRightDiv>
							<Logo />
						</BorderRightDiv>
						<Nav />
					</LogoNav>
					<WalletButtons />
				</Container>
				<Banner />
				{showRestrictedBanner && showRestrictedWarnig.current && (
					<ResrtictedWarningBanner>
						<ResrtictedWarningTextWrap>
							Perpetuals are not available to any persons who are residents of, are located or
							incorporated in, or have a registered agent in a blocked country or a restricted
							territory. More details can be found in our{' '}
							<StyledLink href="/terms" target="_blank">
								Terms of Use
							</StyledLink>
							. If you think this is an error, please contact support in the{' '}
							<StyledLink href="https://discord.gg/dextoro" target="_blank">
								Help center
							</StyledLink>
							.
						</ResrtictedWarningTextWrap>
						<CloseIconWithHover onClick={handleClose} />
					</ResrtictedWarningBanner>
				)}
			</FlexDivCol>
		</MobileHiddenView>
	)
}

const Container = styled.header`
	display: flex;
	justify-content: space-between;
	padding: 5px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	max-height: 48px;
`
const BorderRightDiv = styled.div`
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
`

const LogoNav = styled.div<LogoNavProps>`
	display: flex;
	align-items: center;
	max-width: ${(props) => (props.hasWalletAddress ? 'calc(100% - 320px)' : 'calc(100% - 155px)')};
	nav {
		max-width: calc(100% - 45px);
	}
`
// max-width: calc(100% - 155px);

const ResrtictedWarningBanner = styled.div`
	display: flex;
	align-items: center;
	background-color: #ef6868;
	color: ${(props) => props.theme.colors.selectedTheme.compliance.bannerText};
	text-align: left;
	padding: 12px 24px;
	height: 44px;
	font-size: 14px;
	font-weight: 400;
	margin-top: 1px;
	${media.greaterThan('xxl') &&
	css`
		justify-content: center;
	`}

	svg {
		margin-left: 10px;
		width: 20px;
		height: 20px;
		padding-top: 2px;
		stroke: ${(props) => props.theme.colors.selectedTheme.compliance.bannerText};
		cursor: pointer;
		stroke-width: 3;
		${media.lessThan('xxl') &&
		css`
			align-self: baseline;
		`}
	}
`
const StyledLink = styled(Link)`
	color: ${(props) => props.theme.colors.selectedTheme.compliance.bannerLink};
	font-weight: 400;
	text-decoration: underline;
	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.compliance.bannerText};
		text-decoration: none;
	}
`
const ResrtictedWarningTextWrap = styled.div`
	${media.greaterThan('xxl') &&
	css`
		flex: 1;
	`}
`

export default Header
