import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { FlexDivCol } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'

import DocsIcon from 'assets/svg/social/docs.svg'
import CommunityIcon from 'assets/svg/social/community.svg'
import MoreIcon from 'assets/svg/app/link-new.svg'
import MetricsIcon from 'assets/svg/social/matric.svg'
import BlogIcon from 'assets/svg/social/blog.svg'
import WalletIcon from 'assets/svg/social/wallet.svg'
import XIcon from 'assets/svg/social/x.svg'
import InstaIcon from 'assets/svg/social/instagram.svg'
import YoutubeIcon from 'assets/svg/social/youtube.svg'
import TermsIcon from 'assets/svg/app/terms-icon.svg'
import { useRouter } from 'next/router'

const StyledTermsIcon = styled(TermsIcon)`
	path {
		fill: #6f6e84;
	}
	rect,
	path {
		stroke: #6f6e84;
	}
`

const lists = [
	{ icon: <XIcon />, title: 'Twitter', href: EXTERNAL_LINKS.Social.Twitter, description: '' },
	{
		icon: <CommunityIcon />,
		title: 'Discord',
		href: EXTERNAL_LINKS.Social.Discord,
		description: '',
	},
	{ icon: <BlogIcon />, title: 'Blog', href: EXTERNAL_LINKS.Social.Blog, description: '' },
	{
		icon: <InstaIcon />,
		title: 'Instagram',
		href: EXTERNAL_LINKS.Social.Instagram,
		description: '',
	},
	{ icon: <YoutubeIcon />, title: 'Youtube', href: EXTERNAL_LINKS.Social.Youtube, description: '' },
	{
		icon: <DocsIcon />,
		title: 'Documentation',
		href: EXTERNAL_LINKS.Docs.Documentation,
		description: '',
	},
	{
		icon: <WalletIcon />,
		title: 'Smart Wallet',
		href: EXTERNAL_LINKS.Docs.SmartWallet,
		description: '',
	},
	{ icon: <MetricsIcon />, title: 'Metrics', href: EXTERNAL_LINKS.Docs.Metrics, description: '' },
	{
		icon: <StyledTermsIcon />,
		title: 'Terms of Use',
		href: EXTERNAL_LINKS.Docs.Terms,
		description: '',
		isTerms: true,
	},
]

const More: React.FC<{ setMoreMenu: (value: boolean) => void; onDismiss(): void }> = ({
	setMoreMenu,
	onDismiss,
}) => {
	const { asPath } = useRouter()
	return (
		<Container>
			{lists.map((l, i) => (
				<Link
					href={l.href}
					key={i}
					target={l.isTerms ? undefined : '_blank'}
					onClick={() => {
						if (setMoreMenu && l.isTerms) {
							setMoreMenu(false)
							onDismiss()
						}
					}}
					className={asPath.includes('/terms') && l.isTerms ? 'active-link' : ''}
				>
					<Icon>{l.icon}</Icon>
					<FlexDivCol rowGap="4px">
						<Title>
							{l.title}
							{l.isTerms ? null : (
								<span>
									<MoreIcon className="link-icon" />
								</span>
							)}
						</Title>
						<Description>{l.description}</Description>
					</FlexDivCol>
				</Link>
			))}
		</Container>
	)
}

export default More

const Container = styled.div`
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100% - 68px);
	width: 100%;
	overflow-y: auto;
	z-index: 9999;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};

	a {
		display: flex;
		align-items: center;
		height: 68px;
		padding: 0 28px;
		gap: 16px;
		border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	}

	svg {
		width: 20px;
		height: 20px;
		transition: all 0.15s ease-in-out 0s !important;
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	.link-icon {
		path {
			fill: none;
		}
	}
	.active-link {
		div {
			color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
		}
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
			}
			rect,
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary} !important;
			}
		}
	}
`

// height: 92px;

const Icon = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 22px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};

	span {
		margin-top: 4px;
		margin-left: 6px;

		svg {
			width: 16px;
			height: 16px;
		}
	}
`

const Description = styled.div`
	font-size: 15px;
	line-height: 20px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
`
